import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import {
  BREAKPOINT,
  COLOR,
  FONT_WEIGHT,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { NotificationBanner } from '@latitude/banner';
import { SOFT_QUOTE_LOANS_AU_URL } from '@/utils/constants';

import Layout from '../components/layout';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import PageData from '../data/pages/index.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Promo, { PromoSection } from '../components/Promo/Promo';
import PromoBanner from '../components/lab-components/PromoBanner';
import HeroBanner from '../components/lab-components/HeroBanner';
import CalculatorLinks from '../components/CalculatorLinks/CalculatorLinks';
import QuickLinks from '../components/QuickLinks/QuickLinks';
import imgChevron from '../images/chevron-blue.svg';
import imagePersonalLoan from '../images/PersonalLoanGirl.jpg';

import PageHeaderSection from '@/components/PageLayout/PageHeader';

import heroImage from '../images/hero/hero-home.webp';
import { PageContext } from '@/context/PageContext';

const softQuoteUrl = `${getUrlFromEnv(
  'funnel-start-loans'
)}${SOFT_QUOTE_LOANS_AU_URL}`;

const IndexPage = props => {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [{
    heading: "Why partner with Latitude?",
    featureCards: PageData.whyChooseLatitude
  }]

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer">
        <Helmet>
          <link rel="canonical" href="https://www.latitudefinancial.com.au/" />
          <meta
            name="description"
            content="Latitude Financial Services offers simple, flexible options to help you get ahead. From loans to credit cards to insurance, you can do better. Learn more."
          />
          <title>Credit Cards and Personal Loans | Latitude Financial</title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                For life’s moments
                <br />
                big and small
              </>
            }
            subTitle={
              <>
                As your Partners in Money, our attitude is to be responsible,
                fast and always ready to help. So, what are you looking for
                today?
              </>
            }
            pageImage={heroImage}
            pageImageAlt=""
          >
            <div className="row">
              <div className="text-left col-12 col-sm-10 col-md-12 col-lg-8">
                <HomeButton
                  href="/credit-cards/"
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  trackId="credit-cards"
                >
                  Credit Cards
                </HomeButton>
                <HomeButton
                  href="/personal-loan"
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  trackId="personal-loans"
                >
                  Personal Loans
                </HomeButton>
              </div>
            </div>
          </PageHeaderSection>
        )}
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <PromoSection css="background-color: #F8F8F8;">
            <Promo
              title={
                <>
                  <span
                    css={`
                    @media (min-width: ${BREAKPOINT.LG}) {
                      font-size:32px;
                    }
                  }
                `}
                  >
                    Get your Personal Loan rate in 2 minutes.
                    <br />
                    <span
                      css={`
                        font-weight: ${FONT_WEIGHT.NORMAL};
                      `}
                    >
                      It won&apos;t impact your credit score.
                    </span>
                  </span>
                </>
              }
              frameImage={
                <img src={imagePersonalLoan} alt="Personal Loan Girl" />
              }
              verticalAlignContent
              frameAlignment="left"
              frameBorderColor="#B1DBFF"
              ctaButtons={
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={softQuoteUrl}
                  target="_self"
                  trackId="get-started"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: 'home'
                  }}
                  trackProductId={['PLAULF-WEB']}
                  width="156px"
                  css={`
                    @media (min-width: ${BREAKPOINT.LG}) {
                      // IE11: need to be 'inline' to work with 'text-align' from parent
                      display: inline-flex;
                    }
                  `}
                >
                  Get Rate Estimate
                </Link>
              }
            />
          </PromoSection>
        )}
        <CalculatorLinks />
        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
            .card-icon__image::after {
              background: transparent !important;
            }
            .slick-track .slick-slide:nth-child(2n + 2) {
              -webkit-transform: none !important;
              -ms-transform: none !important;
              transform: none !important;
            }
            .lfs-slide {
              box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }
            .card-icon h5 {
              font-size: 18px !important;
              margin: 10px 0 !important;
            }
            .card-icon__content p {
              color: #696969 !important;
            }
            .whats-important .slick-slider .slick-slide.slick-active {
              margin: 0 5px !important;
            }
          `}
          data={PageData.categories}
          className="whats-important"
          heading="What’s important to you?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />
        <QuickLinks />
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
        />
      </main>
    </Layout>
  );
};

const HomeButton = styled(Link)`
  color: ${COLOR.BLACK} !important;
  margin-bottom: 8px;
  padding-left: 16px;
  justify-content: space-between;
  font-weight: 700;
  @media (min-width: ${BREAKPOINT.LG}) {
    min-width: 360px;
  }

  &:hover {
    color: ${COLOR.BLACK} !important;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }

  ::after {
    content: '';
    background-image: url(${imgChevron});
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    width: 8px;
    height: 14px;
  }
`;

export default IndexPage;
